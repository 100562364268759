import { useState } from "react";
import { Link } from 'react-router-dom';

function Login(props) {
    let sessionStorage = window.sessionStorage;
    let localStorage = window.localStorage;

    let [loginId, setLoginId] = useState("");
    let [loginPassword, setLoginPassword] = useState("");
    let [loginToken, setLoginToken] = useState(localStorage.getItem("token") || "");

    let [savedLoginId, setSavedLoginId] = useState("");
    let [savedLoginPassword, setSavedLoginPassword] = useState("");

    return (
        <>
            <div>
                ID : <input type="text" size={20} onChange={ (e)=>{
                    setLoginId(e.target.value)
                } } />
            </div>
            <div>
                PW : <input type="password" size={20} onChange={ (e)=>{
                    setLoginPassword(e.target.value)
                } } />
            </div>

            <div>
                <Link to="/test">
                    <button onClick={ ()=>{ 
                        sessionStorage.setItem("loginId", loginId);
                        sessionStorage.setItem("loginPassword", loginPassword);
                        setSavedLoginId(sessionStorage.getItem("loginId"));
                        setSavedLoginPassword(sessionStorage.getItem("loginPassword"));

                        localStorage.setItem("token", props.token);
                        setLoginToken("testToken123")
                    } }>Login</button>
                </Link>

                <button onClick={ ()=>{
                    sessionStorage.clear();
                    setSavedLoginId(sessionStorage.getItem("loginId"));
                    setSavedLoginPassword(sessionStorage.getItem("loginPassword"));
                    
                    localStorage.clear();
                    setLoginToken(localStorage.getItem("token"))
                } }>Logout</button>
            </div>
            <div>
                loginId : {savedLoginId}
            </div>
            <div>
                loginPassword : {savedLoginPassword}
            </div>
            <div>
                token : {loginToken}
            </div>
            <div>
                <Link to="/api-test">
                    <button onClick={ ()=>{} }>api test</button>
                </Link>
            </div>
        </>
    )
}
export default Login;