import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export default function AgGrid() {
  const columnDefs = [
    { headerName: '이름', field: 'name', headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true },
    { headerName: '성별', field: 'gender'},
  ];

  const rowData = [
    { name: 'John', gender: '남' },
    { name: 'Alice', gender: '여' },
  ];

  const handleRowClicked = (event) => {
    console.log('클릭된 행 데이터:', event.data);
  };

  const onGridReady = (event) => {
    event.api.sizeColumnsToFit()
  }

  const onCellEditingStarted = (event) => {
    console.log(event.value)
  }

  return (
      <div className="ag-theme-alpine" style={{ height: 400, width: 600 }}>
          <AgGridReact
              columnDefs={columnDefs}
              rowData={rowData}
              rowSelection="multiple"
              suppressRowClickSelection={false}
              suppressMovableColumns={true}
              onRowClicked={handleRowClicked}
              defaultColDef={{
                editable: true,
                sortable: false,
                flex: 1,
              }}
              onGridReady={onGridReady}
              onCellEditingStarted={onCellEditingStarted}
          />
      </div>
  );
}