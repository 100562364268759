import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./login/login";
import Test from "./reducers/test";
import ApiTest from "./apiTest"
import { Helmet } from "react-helmet";
import AgGrid from "./grid/agGrid";


function App() {
	return (
		<div className="App">
			<Helmet title="M&F Solution" />
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Login token="testToken" />}></Route>
					<Route path="/test" element={<Test />}></Route>
          <Route path="/grid" element={<AgGrid />}></Route>
					<Route path="/api-test" element={<ApiTest />}></Route>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;